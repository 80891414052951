<template>
  <div class="profile-edit">
    <VOverlay v-if="_updatingStatus === requestStatus.PENDING">
      <VLoader/>
    </VOverlay>
    <div class="profile-edit__main-header pb-2">
      <div class="profile-edit__name title-sm text-semibold">{{ authData.first_name || 'user' }}
        {{ authData.last_name || '' }}
      </div>
    </div>
    <form class="profile-edit__form pt-2" @submit.prevent="submitForm">
      <div class="row mb-3">
        <div class="col">
          <div class="mb-1">Instant Messenger</div>
          <VSelect
              :options="messengersList"
              :value="form.instant_messaging_id"
              get-type="object"
              size="sm"
              fullWidth
              class="mb-2"
              @input="filterBySelectId($event, 'instant_messaging_id')"
          />
        </div>

        <div class="col">
          <FieldText
              v-if="form.instant_messaging_id > 0"
              v-model="form.instant_messaging_identifier"
              type="text"
              labelText="Instant Messenger Identifier"
              :validation="$v.form.instant_messaging_identifier.$error"
              class="mb-2"
          />
        </div>
      </div>
      <div class="d-flex mt-1 justify-content-end">
        <button class="profile-edit__form-btn mr-2">Save</button>
        <v-button
            type="orange-outline"
            class="btn-xl"
            @click="onCloseEditForm"
        >
          Cancel
        </v-button>
      </div>
    </form>
  </div>
</template>

<script>
import { required, email, minLength } from 'vuelidate/lib/validators';
import FieldText from "@/js/components/common/form/FieldText";
import VSelect from "@/js/components/common/VSelect";
import { toasted } from '@/js/utils/constants';
import { requestStatus } from '@/js/utils/constants';

export default {
  name: "ProfileEdit",
  components: {
    VSelect,
    FieldText,
  },
  props: {
    authData: Object,
    messengersList: Array
  },
  data() {
    return {
      requestStatus,
      currency: {
        id: this.$store.state.auth.currency_id,
        label: this.$store.getters['meta/currencyLabelById'](this.$store.state.auth.currency_id)
      },
      timezone: {
        id: this.$store.state.auth.timezone_id,
        label: this.$store.getters['meta/timezoneLabelById'](this.$store.state.auth.timezone_id)
      },
      account_status: {
        id: this.authData.account_status === 'active' ? 0 : 1,
        label: this.authData.account_status,
      },
      messenger: {
        id: this.authData.instant_messaging_id,
        label: this.messengersList[this.authData.instant_messaging_id].label,
      },
      form: {
        first_name: this.authData.first_name,
        last_name: this.authData.last_name,
        title: this.authData.title,
        email: this.authData.email,
        work_phone: this.authData.work_phone,
        cell_phone: this.authData.cell_phone,
        account_status: this.authData.account_status,
        currency_id: this.$store.state.auth.currency_id,
        timezone_id: this.$store.state.auth.timezone_id,
        instant_messaging_id: this.authData.instant_messaging_id,
        instant_messaging_identifier: this.authData.instant_messaging_identifier,
        language_id: this.authData.language_id
      },

      statusList: [
        {
          id: 0,
          label: 'active'
        },
        {
          id: 1,
          label: 'inactive'
        },
      ]
    };
  },
  validations: {
    form: {
      first_name: {
        required,
        minLength: minLength(1)
      },
      last_name: {
        required,
        minLength: minLength(1)
      },
      email: {
        required,
        email
      },
      work_phone: {},
      title: {},
      cell_phone: {},
      account_status: { required },
      currency_id: { required },
      timezone_id: { required },
      instant_messaging_id: { required },
      instant_messaging_identifier: {},
    }
  },
  computed: {
    _updatingStatus() {
      return this.$store.state.profile.updatingStatus;
    },
  },
  methods: {
    filterBySelectId(option, slug) {
      this.form[slug] = option.id;
    },
    removeEmpty(obj) {
      return Object.entries(obj)
          .filter(([k, v]) => k && v != null && v !== '')
          .reduce((acc, [k, v]) => ({
            ...acc,
            [k]: v
          }), {});
    },
    async submitForm() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      try {
        const data = await this.$store.dispatch('profile/updateProfile', this.removeEmpty(this.form));

        if (data) {
          this.onCloseEditForm();
          this.$toasted.success(toasted.text.success);
        }
      } catch (err) {
        console.log('=> ERR:', err.response.data.message);
        this.$toasted.error(toasted.text.error);
      }
    },
    onCloseEditForm() {
      this.$store.commit('profile/SET_IS_EDITING', false);
    }
  },
  destroyed() {
    this.onCloseEditForm();
  }
};
</script>

<style lang="scss" scoped>
.profile-edit {
  position: relative;

  &__main-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #DDE4EB;
  }

  .row {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
  }

  &__form-btn {
    width: 100px;
    height: 40px;
    background: var(--orange-main);
    border-radius: 8px;
    color: var(--white-main);
    border: 1px solid var(--orange-main);
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
  }
}

</style>