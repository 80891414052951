<template>
  <div class="profile">
    <div class="profile__main">
      <template v-if="$store.state.profile.isEditing">
        <ProfileEdit
            :authData="_authData"
            :messengersList="_messengers"
        />
      </template>
      <template v-else>
        <div class="profile__main-header pb-2">
          <div class="profile__name title-sm text-semibold">{{ _authData.first_name || 'user' }}
            {{ _authData.last_name || '' }}
          </div>
          <v-button
              type="blue"
              class="profile__edit-button"
              icon="edit"
              icon-position="left"
              @click="$store.commit('profile/SET_IS_EDITING', true)"
          >
            Edit
          </v-button>
        </div>
        <div class="profile__main-body pt-3 pb-2 pr-5">
          <div class="profile__row d-flex justify-content-between mb-2">
            <div class="profile__col">
              <div class="mb-3">
                <div class="text-semibold mb-1">Partner ID</div>
                <div class="color-grey-main">{{ _authData.affiliate_id }}</div>
              </div>
              <div class="mb-3">
                <div class="text-semibold mb-1">Title</div>
                <div class="color-grey-main">{{ _authData.title || '-' }}</div>
              </div>
              <div class="mb-3">
                <div class="text-semibold mb-1">Work Phone</div>
                <div class="color-grey-main">{{ _authData.work_phone || '-' }}</div>
              </div>
              <div class="mb-3">
                <div class="text-semibold mb-1">Cell Phone</div>
                <div class="color-grey-main">{{ _authData.cell_phone || '-' }}</div>
              </div>
              <div class="mb-3">
                <div class="text-semibold mb-1">Email</div>
                <div class="color-grey-main">{{ _authData.email || '-' }}</div>
              </div>
              <div class="mb-3">
                <div class="text-semibold mb-1">Instant Messenger</div>
                <div v-if="_authData.instant_messaging_id" class="color-grey-main">
                  <span class="color-blue-main">{{ _profileMessenger.label }}:</span>
                  {{ _authData.instant_messaging_identifier }}
                </div>
                <div v-else> None</div>
              </div>
            </div>
            <div class="profile__col">
              <div class="mb-3">
                <div class="text-semibold mb-1">Account status</div>
                <div class="color-grey-main">{{ _authData.account_status || '-' }}</div>
              </div>
              <div class="mb-3">
                <div class="text-semibold mb-1">Added to System</div>
                <div class="color-grey-main">{{ formattedData(_authData.created_at) || '-' }}</div>
              </div>
              <div class="mb-3">
                <div class="text-semibold mb-1">Last Modification</div>
                <div class="color-grey-main">{{ formattedData(_authData.updated_at) || '-' }}</div>
              </div>
              <div class="mb-3">
                <div class="text-semibold mb-1">Timezone</div>
                <div class="color-grey-main">{{ _profileTimezone }}</div>
              </div>
              <div class="mb-3">
                <div class="text-semibold mb-1">Currency</div>
                <div class="color-grey-main">{{ _profileCurrency || '' }}</div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import ProfileEdit from "@/js/components/pages/settings/profile/ProfileEdit";

export default {
  name: "Profile",
  components: {ProfileEdit},
  computed: {
    _authData() {
      return this.$store.state.auth
    },
    _profileTimezone() {
      return this.$store.getters['meta/timezoneLabelById'](this.$store.state.auth.timezone_id)
    },
    _profileCurrency() {
      return this.$store.getters['meta/currencyLabelById'](this.$store.state.auth.currency_id)
    },
    _profileMessenger() {
      return this._messengers.find(item => item.id === this._authData.instant_messaging_id)
    },
    _messengers() {
      return this.$store.state.meta.messengers
    },
  },
  methods: {
    formattedData(dateTime) {
      return `${moment.utc(dateTime).format('MM/DD/YYYY HH:mm:ss')} UTC`
    }
  }
}
</script>
<style lang="scss">
.profile {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__main {
    max-width: 712px;
    width: 100%;
    padding: 40px 25px;
    background: var(--white-main);
    box-shadow: 0px 3px 10px 3px rgba(43, 72, 105, 0.11);
    border-radius: 8px;
  }

  &__main-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #DDE4EB;

  }

  &__edit-button {
    max-width: 78px;
    height: 30px;
  }
}
</style>